<script>
	import { required, email, maxLength, numeric, minLength } from "vuelidate/lib/validators";
	import appConfig from "@/app.config";
	import Vue from 'vue';
	import axios from 'axios';
	import VueAxios from 'vue-axios';

	Vue.use(VueAxios, axios);
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

	export default {
		page: {
			title: 'Corporate',
			meta: [
				{
					name: 'description',
					content: appConfig.description,
				},
			],
		},
		data: function() {
			return {
				corporateName: '',
				corporateAddress: '',
				adminName: '',
				adminEmail: '',
				adminNumber: '',
				city: '',
				submitted: false,
				isAdminExist: false,
			};
		},
		validations: {
			corporateName: {
				required,
			},
			corporateAddress: {
				required,
			},
			adminName: {
				required,
			},
			adminEmail: {
				required,
				email,
			},
			adminNumber: {
				required,
				minLength: minLength(10),
				maxLength: maxLength(10),
				numeric,
			},
			city: {
				required,
			},
		},
		computed: {},
		mounted: function() {
			document.body.classList.add("authentication-bg");
		},
		methods: {
			addCorporate: function() {
				this.submitted = true;
				this.$v.$touch();
				if (!this.$v.$invalid) {
					this.axios.post('corporate/add-corporate', {
						name: this.corporateName,
						address: this.corporateAddress,
						adminName: this.adminName,
						adminPhoneNumber: this.adminNumber,
						adminEmail: this.adminEmail,
						city: this.city,
					}).then((result) => {
						if (result.data.responseCode == 200) {
							alert(result.data.response);
							window.location.reload();
						}
						if (result.data.responseCode == 400) {
							alert(result.data.response)
						}
					}).catch((error) => {
						console.log(error)
					});
				}
			},
		},
	};
</script>
<style scoped>
	label {
		margin-bottom: 0.5rem !important;
	}
	textarea {
		resize: none;
	}
	input:active, input:focus, textarea:active, textarea:focus {
		outline: none !important;
	}
</style>
<template>
	<div>
		<div class="account-pages my-5 pt-sm-5">
			<div class="container">
				<div class="row align-items-center justify-content-center">
					<div class="col-md-8 col-lg-6 col-xl-5">
						<div class="card">
							<div class="card-body p-4">
								<div class="text-center mt-2">
									<h5 class="text-primary" style="color: #f12d11 !important;">Add Corporate</h5>
								</div>
								<div class="p-2 mt-4">
									<b-form @submit.prevent="addCorporate">
										<b-form-group class="mb-3" label="Corporate Name" label-for="corporate-name">
											<b-form-input id="corporate-name" type="text" v-model="corporateName"
												:class="{'is-invalid': submitted && $v.corporateName.$error}"></b-form-input>
											<div class="invalid-feedback" v-if="$v.corporateName.$error">
												<div v-if="!$v.corporateName.required">Corporate name is required</div>
											</div>
										</b-form-group>
										<b-form-group class="mb-3" label="Corporate Address" label-for="corporate-address">
											<b-form-textarea id="corporate-address" type="textarea" v-model="corporateAddress"
												:class="{'is-invalid': submitted && $v.corporateAddress.$error}"></b-form-textarea>
											<div class="invalid-feedback" v-if="$v.corporateAddress.$error">
												<div v-if="!$v.corporateAddress.required">Corporate address is required</div>
											</div>
										</b-form-group>
										<b-form-group class="mb-3" label="Admin Name" label-for="admin-name">
											<b-form-input id="admin-name" type="text" v-model="adminName"
												:class="{'is-invalid': submitted && $v.adminName.$error}"></b-form-input>
											<div class="invalid-feedback" v-if="$v.adminName.$error">
												<div v-if="!$v.adminName.required">Admin name is required</div>
											</div>
										</b-form-group>
										<b-form-group class="mb-3" label="Admin Email" label-for="admin-email">
											<b-form-input id="admin-email" type="text" v-model="adminEmail"
												:class="{'is-invalid': submitted && $v.adminEmail.$error}"></b-form-input>
											<div class="invalid-feedback" v-if="$v.adminEmail.$error">
												<div v-if="!$v.adminEmail.required">Admin Email is required</div>
												<div v-if="!$v.adminEmail.email">Enter a valid Email</div>
											</div>
										</b-form-group>
										<b-form-group class="mb-3" label="Admin Contact Number" label-for="admin-number">
											<b-form-input id="admin-number" type="text" v-model="adminNumber"
												:class="{'is-invalid': submitted && $v.adminNumber.$error}"></b-form-input>
											<div class="invalid-feedback" v-if="$v.adminNumber.$error">
												<div v-if="!$v.adminNumber.required">Mobile number is required</div>
												<div v-if="!$v.adminNumber.numeric">Enter a valid mobile number</div>
												<div v-if="!$v.adminNumber.maxLength || !$v.adminNumber.minLength">Mobile number must be of 10 digits</div>
											</div>
										</b-form-group>
										<b-form-group class="mb-3" label="City" label-for="admin-city">
											<b-form-input id="admin-city" type="text" v-model="city"
												:class="{'is-invalid': submitted && $v.city.$error}"></b-form-input>
											<div class="invalid-feedback" v-if="$v.city.$error">
												<div v-if="!$v.city.required">City is required</div>
											</div>
										</b-form-group>
										<div class="mt-3 text-end">
											<b-button class="btn-btn-outline-primary mt-4" type="submit" variant="primary">Submit</b-button>
										</div>
									</b-form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>